const SocialsData = [
    {
        id: 1,
        name: 'LinkedIn',
        iconLink: 'https://www.linkedin.com/in/paddygnewton/',
     },
    {
        id: 2,
        name: 'Discord',
        iconLink: 'https://discordapp.com/users/188747649548156928',
    },
    {
        id: 3,
        name: 'GitHub',
        iconLink: 'https://github.com/Padnew',
    },
  ]
  
  export default SocialsData